<mat-sidenav-container class="beyou-container">
  <!-- Side Nav -->
  <mat-sidenav #sidenav [mode]="'side'" [(opened)]="isSidemenuOpen" class="sidenav">
    <!-- Logo -->
    <div class="logo-container">
      <a routerLink="/dashboard"><img src="/assets/svg/logo.svg" alt="Subrise"></a>
    </div>
    <!-- Menu Tree -->
    <app-side-menu></app-side-menu>
    <div class="version-info">{{ appVersion }}</div>
  
  </mat-sidenav>
<!-- Main Content -->
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
<!-- End Main Content -->
</mat-sidenav-container>