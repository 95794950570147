import {
  Auth,
  GoogleAuthProvider,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  idToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  user,
} from '@angular/fire/auth';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAuthService, LoginRequestDto } from '@beyou/app/core/interfaces/services/auth-service.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {

  _user: User | null = null;
  user$ = user(this.auth);
  token$ = new BehaviorSubject<string>('');
  tid:any = null;

  constructor(private auth: Auth) {
    this.user$.subscribe((user) => {
      this._user = user;
      if (user) {
        user?.getIdToken().then((token) => this.token$.next(token));
        if(!this.tid) {
          this.addTokenRefreshInterval();
        }
      } else {
        clearTimeout(this.tid);
        this.tid = null;
        this.token$.next('');
      }
    });
  }

  private addTokenRefreshInterval(): void {
     // call a function every 4 minutes to refresh the token when the user is logged in
     this.tid = setInterval(() => {
        console.log('refreshing token');
        this._user?.getIdToken().then((token) => {
          // print last 10 characters of the token
          const l = token.length;
          if(l > 0){
            console.debug('new token received ...', token.substring(l - 20));
          } else {
            console.debug('token is empty');
          }
          this.token$.next(token);
        });
    }, 4 * 60 * 1000);
  }

  login({ email, password }: LoginRequestDto): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  loginWithGoogle(): Promise<UserCredential> {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  register({ email, password }: LoginRequestDto): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  logout(): Promise<void> {
    return signOut(this.auth);
  }
}
